<template>
  <div class="content">
    
    <div class="background">
      <div class="row">
        <div class="col"><h4>Emails Documentation</h4></div>
        <div class="col-auto">
          <router-link :to="{ name: 'automations' }" class="mb-4 vave-btn">BACK</router-link>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Overview</h4>
          <div>
            <p>Email templates can be created on BeeFree (https://pro.beefree.io), exported and uploaded on Maestro.</p>
            <p>
              You can include variables that will be replaced by Vave before sending the email:

               <table class="table table-bordered mt-4" style="width: 500px;">
                <thead>
                  <tr>
                    <th scope="col">Variable</th>
                    <th scope="col">Example</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr><td>first_name</td><td></td></tr>
                    <tr><td>last_name</td><td></td></tr>
                    <tr><td>email</td><td></td></tr>
                    <tr><td>discount_label</td><td>GET20 / Bulk</td></tr>
                    <tr><td>discount_amount</td><td>20% / -£20.00</td></tr>
                    <tr><td>referral_code</td><td>USER123</td></tr>
                    <tr><td>referral_url</td><td>https://mstore.co.uk/register?rc=USER123</td></tr>
                  </tbody>
                </table>

               <table class="table table-bordered mt-4" style="width: 800px;">
                <thead>
                  <tr>
                    <th scope="col">Variable</th>
                    <th scope="col">Example</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr><td>order_id</td><td>00bbf3b2-99a4-4b6d-9cff-ad6ba98de3a2</td></tr>
                    <tr><td>order_number</td><td>MST:12345</td></tr>
                    <tr><td>total_before_discounts</td><td>£2.00</td></tr>
                    <tr><td>total_after_discounts</td><td>£1.00</td></tr>
                    <tr><td>shipping_name</td><td>Factory default shipping</td></tr>
                    <tr><td>shipping_amount</td><td>£1.00</td></tr>
                    <tr><td>order_total</td><td>£2.00</td></tr>
                    <tr><td>shipping_address</td><td>Alysa Sipes<br>560 Wilderman Ridge<br>M14 9LG<br>Howeburgh<br>GB<br></td></tr>
                    <tr><td>billing_address</td><td>Stone Schamberger<br>17008 Wolff Course<br>M14 9LG<br>Lake Darion<br>GB<br></td></tr>
                  </tbody>
                </table>

                <!-- Tracking -->
               <table class="table table-bordered mt-4" style="width: 800px;">
                <thead>
                  <tr>
                    <th scope="col">Variable</th>
                    <th scope="col">Example</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr><td>tracking_number</td><td>0E05000000000000000</td></tr>
                    <tr><td>carrier</td><td>Royal Mail</td></tr>
                    <tr><td>carrier_service</td><td>ROYAL MAIL 24\48</td></tr>
                    <tr><td>carrier_slug</td><td>royal-mail</td></tr>
                    <tr><td>tracking_url</td><td>https://www.royalmail.com/track-your-item#/tracking-results/0E05000000000000000</td></tr>
                  </tbody>
                </table>
            </p>
            <p>The variables will be replaced based on the Model that triggers the email (E.g. first_name will be the user first name of the User associated with the Order/Cart Model, or the first_name of the User that triggered the email). If you include a coupon variable in the template, you have to tick the "include coupons" checkbox in the Automation. The same applies to the products.</p>
            <div class="row">
              <div class="col-auto">
                <div class style="border: 1px solid black;">
                  <img :src="images.befree" width="800" />
                </div>
              </div>
            </div>

            <h4 class="mt-4">Products</h4>
            <p>To include a list of products, you need to add a row in between of a <span class="badge bg-secondary">StartProductLoop</span> and <span class="badge bg-secondary">EndProductLoop</span> rows.
               The products will be the products of the Cart/Order Model that triggers the email (E.g. products for abandoned Carts/Orders of an Automation)</p>

            <div class="row">
              <div class="col-auto">
                <div class style="border: 1px solid black;">
                  <img :src="images.products" width="400" />
                </div>
              </div>
            </div>

            <p>
              <br />Inside the loop you can create a product using the following variables:
                <table class="table table-bordered mt-4" style="width: 400px;">
                  <thead>
                    <tr>
                      <th scope="col">Variable</th>
                      <th scope="col">Example</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td>%product_sku%</td><td></td></tr>
                    <tr><td>%product_name%</td><td></td></tr>
                    <tr><td>%product_slug%</td><td></td></tr>
                    <tr><td>%product_sub_title%</td><td></td></tr>
                    <tr><td>%product_description%</td><td></td></tr>
                    <tr><td>%product_short_description%</td><td></td></tr>
                    <tr><td>%product_price%</td><td>The current product price</td></tr>
                    <tr>
                      <td>%product_unit_float_price%</td>
                      <td>For Orders, the full or bulk price, per unit</td>
                    </tr>
                    <tr>
                      <td>%product_total_float_price%</td>
                      <td>For Orders, the full or bulk price, multiplied by the quantity</td>
                    </tr>
                    <tr><td>%product_image_url%</td><td></td></tr>
                  </tbody>
                </table>
            </p>

            <p>
              Add a product image by using <span class="badge bg-secondary">%product_image_url%</span>
            </p>

            <div class="row">
              <div class="col-auto">
                <div class style="border: 1px solid black;">
                  <img :src="images.image" width="600" />
                </div>
              </div>
              </div>

            <h4 class="mt-4">Export</h4>
            <p>To export a template just export the "HTML & Images" and upload the file on Maestro.</p>

            <div class="row">
              <div class="col-auto">
                <div class style="border: 1px solid black;">
                  <img :src="images.export1" width="300" />
                </div>
              </div>
              <div class="col-auto">
                <div class style="border: 1px solid black;">
                  <img :src="images.export2" width="400" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: {
        products: require("./../assets/images/products.png"),
        befree: require("./../assets/images/beefree1.png"),
        export1: require("./../assets/images/export1.png"),
        export2: require("./../assets/images/export2.png"),
        image: require("./../assets/images/image.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
</style>